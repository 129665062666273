
const getPkColumnRender = function (key) {
  return (h, { row, index }) => {
    const value = row[key];
    let classStr = '';
    if (value) {
      console.log(key, value);
      if (value < 0) {
        classStr = 'col-is-minus';
      } else if ((typeof value === 'string') && value.includes('+')) {
        classStr = 'col-is-plus';
      }
    }
    return h('yg-attribute-span', {
      attrs: {
        text: row[key],
        className: classStr
      }
    }, row[key]);
  };
};

export default {
  data () {
    return {
      topTable: {
        // 下面数据明细-表头第一层
        topColumns: [
          BaseTableColumn.initData('', '数据对照').setH5ColSpan(2),
          BaseTableColumn.initData('', '端口启停统计').setH5ColSpan(3),
          BaseTableColumn.initData('', '启用设备统计').setH5ColSpan(8),
          BaseTableColumn.initData('', '停用设备统计').setH5ColSpan(6)
        ],
        // 下面数据明细-表头第二层
        columns: [
          BaseTableColumn.initData('h5DateTypeDesc', '数据对照组'),
          BaseTableColumn.initData('updateTime', '数据生成时间'),
          BaseTableColumn.initData('totalPortNum', '端口总数').setSpan({ canTextUnit: 'ko' }),
          BaseTableColumn.initData('enablePortNum', '启用总数').setSpan({ canTextUnit: 'ko' }),
          BaseTableColumn.initData('unablePortNum', '停用总数').setSpan({ canTextUnit: 'ko' }),
          BaseTableColumn.initData('totalEnableDeviceNum', '启用总数').setSpan({ canTextUnit: 'tai' }),
          BaseTableColumn.initData('onlineEnableDeviceNum', '在线总数').setSpan({ canTextUnit: 'tai' }),
          BaseTableColumn.initData('offlineEnableDeviceNum', '离线总数').setSpan({ canTextUnit: 'tai' }),
          BaseTableColumn.initData('tenRoadEnableDeviceNum', '10路').setSpan({ canTextUnit: 'tai' }),
          BaseTableColumn.initData('sixRoadEnableDeviceNum', '6路').setSpan({ canTextUnit: 'tai' }),
          BaseTableColumn.initData('threeRoadEnableDeviceNum', '3路').setSpan({ canTextUnit: 'tai' }),
          BaseTableColumn.initData('twoRoadEnableDeviceNum', '2路').setSpan({ canTextUnit: 'tai' }),
          BaseTableColumn.initData('oneRoadEnableDeviceNum', '1路').setSpan({ canTextUnit: 'tai' }),
          BaseTableColumn.initData('totalUnableDeviceNum', '停用总数').setSpan({ canTextUnit: 'tai' }),
          BaseTableColumn.initData('tenRoadUnableDeviceNum', '10路').setSpan({ canTextUnit: 'tai' }),
          BaseTableColumn.initData('sixRoadUnableDeviceNum', '6路').setSpan({ canTextUnit: 'tai' }),
          BaseTableColumn.initData('threeRoadUnableDeviceNum', '3路').setSpan({ canTextUnit: 'tai' }),
          BaseTableColumn.initData('twoRoadUnableDeviceNum', '2路').setSpan({ canTextUnit: 'tai' }),
          BaseTableColumn.initData('oneRoadUnableDeviceNum', '1路').setSpan({ canTextUnit: 'tai' })
        ]
      },
      bottomTable: {
        topColumns: [
          BaseTableColumn.initData('', '数据对照').setH5ColSpan(2),
          BaseTableColumn.initData('', '余额统计').setH5ColSpan(3),
          BaseTableColumn.initData('', '端口经营统计').setH5ColSpan(2),
          BaseTableColumn.initData('', '充电订单统计').setH5ColSpan(4),
          BaseTableColumn.initData('', '充值订单统计').setH5ColSpan(2)
        ],
        columns: [
          BaseTableColumn.initData('h5DateTypeDesc', '数据对照组'),
          BaseTableColumn.initData('updateTime', '数据生成时间'),
          BaseTableColumn.initData('totalUserAccount', '余额总计').setSpan({ canPrice: true }),
          BaseTableColumn.initData('rechargeMoney', '购买金额').setSpan({ canPrice: true }),
          BaseTableColumn.initData('migrationMoney', '迁入金额').setSpan({ canPrice: true }),
          BaseTableColumn.initData('portUseRate', '端口使用率').setSpan({ canTextUnit: 'p' }),
          BaseTableColumn.initData('portAverageIncome', '端口日均收入').setSpan({ canPrice: true }),
          BaseTableColumn.initData('chargeOrderNum', '单数总计').setSpan({ canTextUnit: 'dan' }),
          BaseTableColumn.initData('chargeOrderIncome', '消费总金额').setSpan({ canPrice: true }),
          BaseTableColumn.initData('wxChargeOrderIncome', '微信消费').setSpan({ canPrice: true }),
          BaseTableColumn.initData('h5CoinChargeOrderIncome', '充电币消费'),
          BaseTableColumn.initData('rechargeOrderNum', '单数总计').setSpan({ canTextUnit: 'dan' }),
          BaseTableColumn.initData('rechargeIncome', '充值收入').setSpan({ canPrice: true })
        ]
      },
      pkTable: {
        topColumns: [
          BaseTableColumn.initData('', '数据对比').setH5ColSpan(3),
          BaseTableColumn.initData('', '端口启停统计').setH5ColSpan(3),
          BaseTableColumn.initData('', '启用设备统计').setH5ColSpan(3),
          BaseTableColumn.initData('', '停用设备统计').setH5ColSpan(1),
          BaseTableColumn.initData('', '余额统计').setH5ColSpan(3),
          BaseTableColumn.initData('', '端口经营统计').setH5ColSpan(2),
          BaseTableColumn.initData('', '充电订单统计').setH5ColSpan(4),
          BaseTableColumn.initData('', '充值订单统计').setH5ColSpan(2)
        ],
        columns: [
          BaseTableColumn.initData('h5PkTitle', '对比项'),
          BaseTableColumn.initData('h5DateTypeDesc', '数据对照组'),
          BaseTableColumn.initData('updateTime', '数据生成时间'),
          BaseTableColumn.initData('totalPortNum', '端口总数').setRender(getPkColumnRender('totalPortNum')),
          BaseTableColumn.initData('enablePortNum', '启用总数').setRender(getPkColumnRender('enablePortNum')),
          BaseTableColumn.initData('unablePortNum', '停用总数').setRender(getPkColumnRender('unablePortNum')),
          BaseTableColumn.initData('totalEnableDeviceNum', '启用总数').setRender(getPkColumnRender('totalEnableDeviceNum')),
          BaseTableColumn.initData('onlineEnableDeviceNum', '在线总数').setRender(getPkColumnRender('onlineEnableDeviceNum')),
          BaseTableColumn.initData('offlineEnableDeviceNum', '离线总数').setRender(getPkColumnRender('offlineEnableDeviceNum')),
          BaseTableColumn.initData('totalUnableDeviceNum', '停用总数').setRender(getPkColumnRender('totalUnableDeviceNum')),
          BaseTableColumn.initData('totalUserAccount', '余额总计').setRender(getPkColumnRender('totalUserAccount')),
          BaseTableColumn.initData('rechargeMoney', '购买金额').setRender(getPkColumnRender('rechargeMoney')),
          BaseTableColumn.initData('migrationMoney', '迁入金额').setRender(getPkColumnRender('migrationMoney')),
          BaseTableColumn.initData('portUseRate', '端口使用率(%)').setRender(getPkColumnRender('portUseRate')),
          BaseTableColumn.initData('portAverageIncome', '端口日均收入').setRender(getPkColumnRender('portAverageIncome')),
          BaseTableColumn.initData('chargeOrderNum', '单数总计').setRender(getPkColumnRender('chargeOrderNum')),
          BaseTableColumn.initData('chargeOrderIncome', '消费总金额').setRender(getPkColumnRender('chargeOrderIncome')),
          BaseTableColumn.initData('wxChargeOrderIncome', '微信消费').setRender(getPkColumnRender('wxChargeOrderIncome')),
          BaseTableColumn.initData('chargeOrderIncome', '充电币消费').setRender(getPkColumnRender('chargeOrderIncome')),
          BaseTableColumn.initData('rechargeOrderNum', '单数总计').setRender(getPkColumnRender('rechargeOrderNum')),
          BaseTableColumn.initData('rechargeIncome', '充值收入').setRender(getPkColumnRender('rechargeIncome'))
        ]
      }
    };
  }
};
