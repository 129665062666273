import BaseModel from '@/libs/base/dataModel/BaseModel';
import { getPastDayFromToday } from '@/libs/utils';

function isStr (v) {
  return typeof v === 'string';
}

export class DateAnalysisTopModel extends BaseModel {
  constructor () {
    super();
    // 更新时间
    this.updateTime = '';
    // 当前端口总数
    this.totalPortNum = '';
    // 当前端口启用数
    this.enablePortNum = '';
    // 当前端口停用数
    this.unablePortNum = '';
    // 当前启用设备总数
    this.totalEnableDeviceNum = '';
    // 当前启用在线设备数
    this.onlineEnableDeviceNum = '';
    // 当前启用离线设备数
    this.offlineEnableDeviceNum = '';
    // 启用1路设备数
    this.oneRoadEnableDeviceNum = '';
    // 启用2路设备数
    this.twoRoadEnableDeviceNum = '';
    // 启用3路设备数
    this.threeRoadEnableDeviceNum = '';
    // 启用6路设备数
    this.sixRoadEnableDeviceNum = '';
    // 启用10路设备数
    this.tenRoadEnableDeviceNum = '';
    // 当前停用设备总数
    this.totalUnableDeviceNum = '';
    // 停用1路设备数
    this.oneRoadUnableDeviceNum = '';
    // 停用2路设备数
    this.twoRoadUnableDeviceNum = '';
    // 停用3路设备数
    this.threeRoadUnableDeviceNum = '';
    // 停用6路设备数
    this.sixRoadUnableDeviceNum = '';
    // 停用10路设备数
    this.tenRoadUnableDeviceNum = '';

    this.h5DateTypeDesc = '';
    // 日期
    this.h5Date = '';
    // 日期对应的时间戳(用于排序)
    this.h5DateTime = 0;
    // PK标题
    this.h5PkTitle = '';
  }

  initData (resData) {
    super.initData(resData);
    if (resData.updateTime) {
      this.updateTime = resData.updateTime.replaceAll('-', '.');
    }

    this.h5DateTime = (new Date(this.h5Date)).getTime();

    const pastDay = getPastDayFromToday(this.h5Date);
    if (pastDay === 0) {
      this.h5DateTypeDesc = '今日概览';
    } else if (pastDay === -1) {
      this.h5DateTypeDesc = '昨日概览';
    } else {
      const absDay = Math.abs(pastDay);
      this.h5DateTypeDesc = `${absDay}天前概览(${this.h5Date.replaceAll('-', '.')})`;
    }
    return this;
  }

  static calculateNumber (model1, model2) {
    const resModel = new DateAnalysisTopModel();
    for (const key in model1) {
      if (!isStr(model1[key]) && !isStr(model2[key])) {
        const value1 = Number(model1[key]) || 0;
        const value2 = Number(model2[key]) || 0;
        if (!Number.isNaN(value1) && !Number.isNaN(value2)) {
          resModel[key] = Number((value1 - value2).toFixed(6));
          if (resModel[key] > 0) {
            resModel[key] = '+' + resModel[key];
          }
        }
      }
    }
    return resModel;
  }
}

export class DateAnalysisBottomModel extends BaseModel {
  constructor () {
    super();
    // 当前用户余额
    this.totalUserAccount = '';
    // 购买金额
    this.rechargeMoney = '';
    // 迁入金额
    this.migrationMoney = '';
    // 今日端口使用率( 100% = 100)
    this.portUseRate = '';
    // 端口日均收入
    this.portAverageIncome = '';
    // 今日充电订单数
    this.chargeOrderNum = '';
    // 今日充电订单消费金额
    this.chargeOrderIncome = '';
    // 今日充电订单消费金额-微信
    this.wxChargeOrderIncome = '';
    // 今日充电订单消费金额-充电币
    this.coinChargeOrderIncome = '';
    // 今日充电订单消费金额-充电币-迁入金额
    this.migrationChargeOrderIncome = '';
    // 今日充值收入
    this.rechargeIncome = '';
    // 今日充值订单
    this.rechargeOrderNum = '';

    this.h5DateTypeDesc = '';
    // 今日充电订单消费金额-充电币
    this.h5CoinChargeOrderIncome = '';
    // 日期
    this.h5Date = '';
    // 日期对应的时间戳(用于排序)
    this.h5DateTime = 0;
  }

  initData (resData) {
    super.initData(resData);
    if (resData.updateTime) {
      this.updateTime = resData.updateTime.replaceAll('-', '.');
    }

    this.h5DateTime = (new Date(this.h5Date)).getTime();

    const pastDay = getPastDayFromToday(this.h5Date);
    if (pastDay === 0) {
      this.h5DateTypeDesc = '今日概览';
    } else if (pastDay === -1) {
      this.h5DateTypeDesc = '昨日概览';
    } else {
      const absDay = Math.abs(pastDay);
      this.h5DateTypeDesc = `${absDay}天前概览(${this.h5Date.replaceAll('-', '.')})`;
    }

    this.h5CoinChargeOrderIncome = resData.coinChargeOrderIncome;
    if (resData.migrationChargeOrderIncome) {
      this.h5CoinChargeOrderIncome += `(含${resData.migrationChargeOrderIncome}迁入金额)`;
    }
    return this;
  }

  static calculateNumber (model1, model2) {
    const resModel = new DateAnalysisBottomModel();
    for (const key in model1) {
      if (!isStr(model1[key]) && !isStr(model2[key])) {
        const value1 = Number(model1[key]) || 0;
        const value2 = Number(model2[key]) || 0;
        if (!Number.isNaN(value1) && !Number.isNaN(value2)) {
          resModel[key] = Number((value1 - value2).toFixed(6));
          if (resModel[key] > 0) {
            resModel[key] = '+' + resModel[key];
          }
        }
      }
    }
    return resModel;
  }
}
