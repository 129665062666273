<!--
    Created by 王丽莎 on 2024/06/28.
    Copyright © 2023年 云柜-金星晖. All rights reserved.
    界面名称:数据统计-数据分析
-->
<style lang="less">
#data-analysis {
  margin: 0 -@containerGap;
  padding: 0 @containerGap;
  min-width: 700px;
  background-color: @backColorStrong;
  .add-more {
    .display-flex();
    border: 1px solid @lineColorNormal;
    border-top: 0;
  }
  .top-time-box {
    padding-bottom: @containerGap;
    .display-flex();
    justify-content: space-between;
    .time {
      margin-right: @containerGap;
      color: @textColorLight;
    }
  }
  .pk-box {
    position: relative;
    margin-top: @containerGap;
    padding-top: @containerGap * 2;
    height: 200px;
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: -@containerGap;
      right: -@containerGap;
      height: @containerGap;
      background-color: @backColorNormal;
    }
    .pk-tit {
      .display-flex();
      justify-content: flex-start;
      align-items: flex-end;
      font-size: 15px;
      line-height: 15px;
      font-weight: 600;
      .small {
        margin-left: @containerGap * 0.5;
        font-size: 12px;
        line-height: 12px;
        font-weight: 400;
        color: @textColorLight;
      }
    }
    .choose-date-outer {
      margin-top: @containerGap;
      display: flex;
      .choose-date-box {
        margin-right: @containerGap;
        .display-flex();
        .label {
          margin-right: 5px;
        }
      }
    }
    .col-is-minus {
      font-weight: 600;
      color: @finishColor;
    }
    .col-is-plus {
      font-weight: 600;
      color: @errorColor;
    }
  }
}
</style>

<template>
  <section id="data-analysis">
    <div
      v-permission="'system:statis:days:overview'"
      class="top-time-box"
    >
      <div class="h4">
        统计概览
      </div>
      <div class="display-flex display-flex-v-center">
        <span class="time">末次拉取数据:{{ currentReqTime || '-' }}</span>
        <yg-button
          type="adorn"
          size="small"
          @click="requestData"
        >
          <Icon type="md-refresh-circle" />
          <span style="margin-left: 5px">重新拉取</span>
        </yg-button>
      </div>
    </div>
    <yg-simple-table
      v-permission="'system:statis:days:overview'"
      top-title="设备统计概览"
      :top-columns="topTable.topColumns"
      :columns="topTable.columns"
      :show-control="false"
      :need-storage-column-key="false"
      :show-pager="false"
      :data="topTableData"
    />
    <div class="margin-primary-top padding-primary-top">
      <yg-simple-table
        v-permission="'system:statis:days:overview'"
        top-title="其他统计概览"
        :top-columns="bottomTable.topColumns"
        :columns="bottomTable.columns"
        :need-storage-column-key="false"
        :show-control="false"
        :show-pager="false"
        :data="bottomTableData"
      />
    </div>
    <div
      class="pk-box"
    >
      <div class="pk-tit h4">
        数据对比
        <div class="small">
          请选择两个日期进行数据对
        </div>
      </div>
      <div class="choose-date-outer">
        <div class="choose-date-box">
          <div class="label">
            目标日期:
          </div>
          <div class="date-1">
            <DatePicker
              v-model="targetDate1"
              transfer
              :options="dateOptions"
              style="width: 100%"
            />
          </div>
        </div>
        <div class="choose-date-box">
          <div class="label">
            对比日期:
          </div>
          <div class="date-1">
            <DatePicker
              v-model="targetDate2"
              transfer
              style="width: 100%"
              :options="dateOptions"
            />
          </div>
        </div>
        <yg-button
          type="primary"
          :disabled="!targetDate1 || !targetDate2"
          disabled-text="请选择两个日期"
          @click="_clickPk"
        >
          进行对比
        </yg-button>
      </div>
      <div
        v-if="pkTableData.length"
        class="margin-primary-top"
      >
        <yg-simple-table
          v-permission="'system:statis:days:overview'"
          top-title="数据对比"
          :top-columns="pkTable.topColumns"
          :columns="pkTable.columns"
          :max-height="-1"
          :need-storage-column-key="false"
          :show-control="false"
          :show-pager="false"
          :data="pkTableData"
        />
      </div>
    </div>
  </section>
</template>

<script>
import { DateAnalysisTopModel, DateAnalysisBottomModel } from './model/DateAnalysisModel';
import { sortBy, getLastDate, getNowTime, formatDate } from '@/libs/utils';
import DataStatisticsApiSet from '@/request/api/DataStatisticsApiSet';
import ColumnMixin from './mixin/tableColumn';

export default {
  components: {},
  mixins: [ColumnMixin],
  data () {
    return {
      page: { current: 1, total: 0 },
      // 末次数据请求时间
      currentReqTime: '',
      topTableData: [],
      bottomTableData: [],
      pkTableData: [],
      targetDate1: '',
      targetDate2: '',
      dateOptions: {
        shortcuts: [
          {
            text: '今天',
            value () {
              return getLastDate(0);
            }
          },
          {
            text: '昨天',
            value () {
              return getLastDate(1);
            }
          },
          {
            text: '7天前',
            value () {
              return getLastDate(7);
            }
          },
          {
            text: '30天前',
            value () {
              return getLastDate(30);
            }
          },
          {
            text: '60天前',
            value () {
              return getLastDate(60);
            }
          },
          {
            text: '90天前',
            value () {
              return getLastDate(90);
            }
          }
        ]
      }
    };
  },
  created () {
    this.requestData();
  },
  mounted () {},
  methods: {
    /** ******************* 工具方法开始 ******************* **/
    /** ******************* 工具方法结束 ******************* **/

    /** ******************* 回调事件开始 ******************* **/
    /** ******************* 回调事件结束 ******************* **/

    /** ******************* 点击事件开始 ******************* **/
    /** ******************* 点击事件结束 ******************* **/

    /** ******************* 数据请求开始 ******************* **/
    _clickPk () {
      this.pkTableData = [];
      this.targetDate1 = formatDate(new Date(this.targetDate1));
      this.targetDate2 = formatDate(new Date(this.targetDate2));
      if (this.targetDate1 === this.targetDate2) {
        return this.$Message.warning('请勿选择同一日期');
      }
      this.requestPk();
    },
    requestData () {
      const api1 = DataStatisticsApiSet.getDaysOverview(getLastDate(0));
      const api2 = DataStatisticsApiSet.getDaysOverview(getLastDate(1));
      const api3 = DataStatisticsApiSet.getDaysOverview(getLastDate(7));
      const api4 = DataStatisticsApiSet.getDaysOverview(getLastDate(30));
      const tempArr = [api1, api2, api3, api4];
      const apiArr = [this.$http(api1), this.$http(api2), this.$http(api3), this.$http(api4)];
      Promise.all(apiArr).then(resArr => {
        const datList = [];
        resArr.forEach((v, index) => {
          const data = v.data;
          data.h5Date = tempArr[index].params.date;
          datList.push(data);
        });
        this.topTableData = DateAnalysisTopModel.initListWithDataList(datList);
        this.topTableData.sort(sortBy('h5DateTime', 0));
        this.bottomTableData = DateAnalysisBottomModel.initListWithDataList(datList);
        this.bottomTableData.sort(sortBy('h5DateTime', 0));
        this.currentReqTime = getNowTime('.');
      });
    },
    requestPk () {
      const api1 = DataStatisticsApiSet.getDaysOverview(this.targetDate1);
      const api2 = DataStatisticsApiSet.getDaysOverview(this.targetDate2);
      const apiArr = [this.$http(api1), this.$http(api2)];
      Promise.all(apiArr).then(resArr => {
        const datList = [];
        resArr[0].data.h5Date = this.targetDate1;
        resArr[0].data.h5PkTitle = '目标日期';
        resArr[1].data.h5Date = this.targetDate2;
        resArr[1].data.h5PkTitle = '对比日期';
        const rowModel1Top = DateAnalysisTopModel.initModelWithData(resArr[0].data);
        const rowModel1Bottom = DateAnalysisBottomModel.initModelWithData(resArr[0].data);
        const rowModel2Top = DateAnalysisTopModel.initModelWithData(resArr[1].data);
        const rowModel2Bottom = DateAnalysisBottomModel.initModelWithData(resArr[1].data);

        const rowPkTop = DateAnalysisTopModel.calculateNumber(rowModel1Top, rowModel2Top);
        const rowPkBottom = DateAnalysisBottomModel.calculateNumber(rowModel1Bottom, rowModel2Bottom);
        datList.push({ ...rowModel1Top, ...rowModel1Bottom });
        datList.push({ ...rowModel2Top, ...rowModel2Bottom });
        datList.push({ ...rowPkTop, ...rowPkBottom });
        this.pkTableData = datList;
      });
    }
    /** ******************* 数据请求结束 ******************* **/
  }
};
</script>
